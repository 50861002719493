import { render, staticRenderFns } from "./LocaleEdit.vue?vue&type=template&id=022b30e2&scoped=true&"
import script from "./LocaleEdit.vue?vue&type=script&lang=js&"
export * from "./LocaleEdit.vue?vue&type=script&lang=js&"
import style0 from "./LocaleEdit.vue?vue&type=style&index=0&id=022b30e2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022b30e2",
  null
  
)

export default component.exports