
import ContentTypesComboMulti from './fields/ContentTypesComboMulti.vue'
import SystemTags from './fields/SystemTags.vue'
import TemplateBasedList from './TemplateBasedList.vue'

export default {
	name: 'RoleConditionEditor',
	components: { TemplateBasedList, SystemTags, ContentTypesComboMulti },
	props: {
		value: Array,
		action: Array, // [ 'ch:useFeature' | 'ch:upsertEntry' | 'ch:deleteEntry' | 'ch:publishEntry' | 'ch:unpublishEntry' | 'ch:tagEntry' ]
	},
	data: () => ({
		conditionTemplates: {
			// TODO: if action[0] is feature, only show feature conditions
			// TODO: we need a feature to filter conditions based on the action..
			//       add an action param?
			'String Equal': { type: 'stringEquals', key: '', param: [] },
			'String Not Equal': { type: 'stringNotEquals', key: '', param: [] },
			// TODO: are these really only relevant for tags(?)
			'Tags Contain': { type: 'listContains', key: 'ch:entry/tags', param: [] },
			'Tags Do Not Contain': { type: 'listNotContains', key: 'ch:entry/tags', param: [] },
		},
	}),
}
