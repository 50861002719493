// https://chillimetrix.alturos.com/confluence/display/CH/Authorization

import { parsePolicies, buildRightsTree, Action, Feature, Rights, userIsOrganisationAdmin } from './permission-utils'

export default {
	inject: [ 'baseEndpoint', 'me' ],
	data: () => ({
		mePolicy: null,
		policies: null,
	}),
	provide() {
		return {
			// we provide ourselves to the children
			permissions: this,
		}
	},
	computed: {
		// TODO: refactor to use computed more to avoid code repetition and multi-calls..
		userContentTypesForCreate() {
			return this.userGetContentTypesForCreate
		},
		rightsTree() {
			return buildRightsTree(this.policies)
		},
	},
	methods: {
		async loadMePolicy() {
			if (!this.$route.params.spaceId) return
			const spaceEndpoint = this.baseEndpoint + '/spaces/' + this.$route.params.spaceId
			const mePolicy = await this.$httpGet(spaceEndpoint + '/users/me/ui_policy_settings')
if (localStorage.mockMePolicy == 'true') {
console.warn('mocking mePolicy (localStorage.mockMePolicy)')
mePolicy.isAdmin = false
mePolicy.features = ['entry']
mePolicy.policies = `
// GENERATED_UI
user:canUseFeature("entry");
user:isAllowed("read", [], []);
user:isAllowed("upsert", [], ["ZillertalArena","webx"]);
user:isAllowed("publish", [], ["ZillertalArena","webx"]);
user:isAllowed("delete", [], ["ZillertalArena","webx"]);
user:isDenied("upsert", ["region","productCategory"]);
user:isDenied("delete", ["region","productCategory"]);
user:tagWith("zillertalActivcard");
user:tagWith("myzillertalappweb");
user:tagWith("webx");
`
/*
user:isDenied("upsert", ["test"]);
user:isDenied("delete", ["test"]);
*/
}
			this.mePolicy = mePolicy

			// we pass false for extract, because we have to understand the whole policy
			this.policies = parsePolicies(this.mePolicy.policies, false)
		},
		userIsAdmin() {
			// TODO: new permission system: action == [ '*' ] && resource == [ '*' ] && condition == []
			if (!this.mePolicy) return false
			return this.mePolicy.isAdmin
		},
		userIsOrganisationAdmin() {
			if (!this.userIsAdmin()) return false
			if (!this.me) return false
			return userIsOrganisationAdmin(this.me.email)
		},
		userCanUseFeature(feature: Feature) {
			// we removed these features (included for all), but keep the permission checks
			if ((feature as any) == 'task') return true
			if ((feature as any) == 'comment') return true
			if ((feature as any) == 'base') return true

			if (!this.mePolicy) return false
			if (this.userIsAdmin()) return true
			return this.mePolicy.features.includes(feature)
		},
		userGetContentTypesForCreate() {
			const all = Object.keys(window['typeLookup'])
			if (!this.mePolicy) return all
			// admins may do everything
			if (this.userIsAdmin()) return all
			// if the user can not use the entry feature, he can not create anything
 			if (!this.userCanUseFeature('entry')) return []

			const r = []
			for (const t in this.rightsTree) {
				if (!this.rightsTree[t].upsert?.allow) continue
				if (this.rightsTree[t].upsert?.deny) continue
				// at CT level we can ignore tags
				r.push(t)
			}
			return r
		},
		userIsAllowedActionOnType(action, contentType) {
			if (!this.mePolicy) return true
			// admins may do everything
			if (this.userIsAdmin()) return true
			// if the user can not use the entry feature, he can not create anything
 			if (!this.userCanUseFeature('entry')) return false

			const rights: Rights = this.rightsTree[contentType]?.[action]
			if (!rights) return false
			// at CT level we can ignore tags
			if (rights.deny === true) return false
			if (rights.allow === true) return true
			// on CT we give the user permission even if he is restricted to tags
			if ((rights.allow as [])?.length) return true
			return false
		},
		userCanCreateAssets() {
			return this.userIsAllowedActionOnType('upsert', 'contentHubAsset')
		},
		userCanUseActionOnAsset(action: Action, asset: any) {
			//if (action == 'publish' && !this.userCanUseFeature('publish')) return false
			return this.userIsAllowedActionOnType(action, 'contentHubAsset')
		},
		isAllowedForTags(entryTags: string[], action: Action, contentType: string) {
//console.log('isAllowedForTags', entryTags, action, contentType)
			const rights: Rights = this.rightsTree[contentType]?.[action]
			if (!rights) return false
			if (rights.deny === true) return false
			if (rights.deny !== false && rights.deny?.some(tag => entryTags.includes(tag))) return false
			if (rights.allow === true) return true
			if (rights.allow !== false && rights.allow?.some(tag => entryTags.includes(tag))) return true
			return false
		},
		userIsAllowedActionOnEntry(action: Action, entry: any) {
//console.log('userIsAllowedActionOnEntry', action, entry)
			if (!this.mePolicy) return true
			if (this.userIsAdmin()) return true
			// NOTE: for the entry tags there is no fallback to 'all' when empty
			//if (action == 'publish' && !this.userCanUseFeature('publish')) return false
			return this.isAllowedForTags(entry?.metadata?.tags?.map(tag => tag?.sys?.id ?? tag) ?? [], action, entry.sys.contentType.sys.id)
		},
		iCan(action: Action, entity: any) {
//console.log('iCan', action, entity)
			if (typeof entity == 'string') {
				return this.userIsAllowedActionOnType(action, entity)
			}
			if (entity?.sys?.type) {
				if (entity.sys.type == 'Asset') return this.userIsAllowedActionOnEntry(action, entity)
				if (entity.sys.type == 'Entry') return this.userIsAllowedActionOnEntry(action, entity)
				if (entity.sys.type == 'ContentType') return this.userIsAllowedActionOnType(action, entity.sys.id)
			}
		},
		iCanCreate(entity: any) { return this.iCan('upsert', entity) },
		iCanUpdate(entity: any) { return this.iCan('upsert', entity) },
		iCanDelete(entity: any) { return this.iCan('delete', entity) },
		iCanPublish(entity: any) { return this.iCan('publish', entity) },
		iCanUse(feature) { return this.userCanUseFeature(feature) },
	},
	async mounted() {
		// TODO: watch the spaceId?

		// we make sure that we only load the policies once
		await this.loadMePolicy()

		//console.log('UGCTFC', this.userGetContentTypesForCreate())
		//console.log('UCUAOE', this.userCanUseActionOnEntry('create', { sys: { contentType: { sys: { id: 'accommodationInfoGroup' } } }, tags: [] }))
	},
}