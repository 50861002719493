<template>
	<div class="RoleEdit" style="height: 100%;" v-if="role?.policies != 'user:isAdmin(true);'">
		<Head :hasBack="true" @back="close()">
			<div class="heading">
				<h1>
					Role
				</h1>
			</div>
			<div class="actions">
				<ActionButton class="delete" @click="del()" v-if="id != 'new'">Delete</ActionButton>
				<ActionButton class="save" @click="saveAndClose()">Save</ActionButton>
			</div>
		</Head>
		<div class="body" v-if="role" style="display: flex; height: 100%;">
			<div class="main" style="margin: 30px; flex: 1 1 0;">
				<label for="name">Name (required)</label>
				<input type="text" class="input" id="name" v-model="role.name" />

				<label for="name">Description</label>
				<input type="text" class="input" id="description" v-model="role.description" />

				<RoleEditIam ref="editor" :role="role" v-if="role.description == 'IAM'" />
				<RoleEditDatalog ref="editor" :role="role" v-else />
			</div>
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'
import ActionButton from '../components/ActionButton.vue'
import ProviderMixin from './ProviderMixin'
import RoleEditDatalog from './RoleEditDatalog.vue'
import RoleEditIam from './RoleEditIam.vue'

export default {
	name: 'RoleEdit',
	mixins: [ ProviderMixin ],
	components: { Sidebar, Head, ActionButton, RoleEditDatalog, RoleEditIam },
	inject: [ 'base', 'baseEndpoint', 'spaceId' ],
	data: () => ({
		id: null,
		role: null,
	}),
	methods: {
		async load() {
			if (this.id == 'new') {
				return this.role = { name: '', description: '', policies: '', sys: {} }
			}
			this.role = await this.$httpGet(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles/${ this.id }`)
			this.$refs.editor?.afterLoad?.()
		},
		async save() {
			this.$refs.editor.beforeSave()
			if (this.id == 'new') {
				await this.$httpPost(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles`, this.role)
				this.load()
				return
			}
			const role = await this.$httpPut(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles/${ this.role.sys.id }`, this.role)
			//this.role = role.item
		},
		close() {
			this.$router.push(this.base + '/settings/roles')
		},
		async del() {
			if (!confirm('Are you sure you want to delete this role?')) return
			await this.$httpDelete(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles/${ this.role.sys.id }`)
			this.close()
		},
		async saveAndClose() {
			await this.save()
			this.close()
		},
	},
	async mounted() {
		this.id = this.$route.params.id
		document.title = 'Role Edit'
		await this.load()
	},
}
</script>

<style scoped>
label { font-size: 14px; display: block; margin-top: 10px; margin-bottom: 4px; }
</style>