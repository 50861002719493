<template>
	<div class="locales" style="height: 100%;">
		<Head :hasBack="true" @back="close()" v-if="locale">
			<div class="heading">
				<h1 v-if="locale" style="display: flex; width: 100%;">
					<div style="flex: 1;">{{ locale.name ? locale.name : 'New locale' }}</div>
				</h1>
			</div>
			<div class="actions">
				<ActionButton class="cancel" @click="close()">Cancel</ActionButton>
				<ActionButton class="delete" @click="deleteLocale()" v-if="id != 'new'">Delete</ActionButton>
				<ActionButton class="save" @click="saveAndClose()">Save</ActionButton>
			</div>
		</Head>
		<div class="body" style="margin: 25px; margin-bottom: 50px; font-size: 14px;" v-if="locale">
			<h2>Locale</h2>
			<!-- TODO: when code changes -> set name based on that! -->
			<select class="localePicker" v-model="locale.code">
				<option :value="''">Select a locale</option>
				<option :value="locale.code">{{ locale.name }} ({{ locale.code }})</option>
				<option v-for="locale of allLocales" :key="locale.code" :value="locale.code">{{ locale.name }} ({{ locale.code }})</option>
			</select>

			<h2>Fallback locale</h2>
			<p>If no content is provided for the locale above, the Delivery API will return content in a locale specified below:</p>
			<select class="localePicker" v-model="locale.fallbackCode">
				<!-- TODO: skip the current locale! -->
				<option :value="null">None (no fallback)</option>
				<option v-for="locale of locales" :key="locale.code" :value="locale.code">{{ locale.name }} ({{ locale.code }})</option>
			</select>

			<Info>
				If you have required fields in your content model, <b>enable empty required fields</b> (there’s a checkbox below). You need to do that because required fields can’t be published when content in a certain locale is not provided.
			</Info>

			<h2>Locale settings</h2>
			<div class="checkbox">
				<!-- TODO: when should this be disabled? is enabled only in french.. -->
				<input type="checkbox" id="contentDeliveryApi" v-model="locale.contentDeliveryApi" />
				<label for="contentDeliveryApi">Enable this locale in response</label>
				Includes locale in the Delivery API response. You can't change this because this locale is being used as a fallback.
			</div>
			<div class="checkbox">
				<input type="checkbox" id="contentManagementApi" v-model="locale.contentManagementApi" />
				<label for="contentManagementApi">Enable editing for this locale</label>
				Displays locale to editors and enables it in Management API.
			</div>
			<div class="checkbox">
				<input type="checkbox" id="optional" v-model="locale.optional" />
				<label for="optional">Allow empty fields for this locale</label>
				Entries with required fields can still be published if locale is empty.
			</div>
		</div>
	</div>
</template>

<script>
import Head from './Head.vue'
import Info from './Info.vue'
import ActionButton from '../components/ActionButton.vue'
// TODO: disable a bunch of fields on the default locale
// TODO: support create mode

export default {
	name: 'LocaleEdit',
	components: { Head, Info, ActionButton },
	inject: [ 'base', 'endpoint' ],
	data: () => ({
		id: null,
		locales: null,
		locale: null,
		allLocales: [
			{ code: 'ar', name: 'Arabic' },
			{ code: 'eu', name: 'Basque' },
			{ code: 'bg', name: 'Bulgarian' },
			{ code: 'ca', name: 'Catalan' },
			{ code: 'hr', name: 'Croatian' },
			{ code: 'cs', name: 'Czech' },
			{ code: 'da', name: 'Danish' },
			{ code: 'nl', name: 'Dutch' },
			{ code: 'en', name: 'English (US)' },
			{ code: 'en-GB', name: 'English (UK)' },
			{ code: 'et', name: 'Estonian' },
			{ code: 'fi', name: 'Finnish' },
			{ code: 'fr', name: 'French' },
			{ code: 'de', name: 'German' },
			{ code: 'el', name: 'Greek' },
			{ code: 'gu', name: 'Gujarati' },
			{ code: 'iw', name: 'Hebrew' },
			{ code: 'hi', name: 'Hindi' },
			{ code: 'hu', name: 'Hungarian' },
			{ code: 'is', name: 'Icelandic' },
			{ code: 'id', name: 'Indonesian' },
			{ code: 'it', name: 'Italian' },
			{ code: 'ja', name: 'Japanese' },
			{ code: 'kn', name: 'Kannada' },
			{ code: 'ko', name: 'Korean' },
			{ code: 'lv', name: 'Latvian' },
			{ code: 'lt', name: 'Lithuanian' },
			{ code: 'ms', name: 'Malay' },
			{ code: 'ml', name: 'Malayalam' },
			{ code: 'mr', name: 'Marathi' },
			{ code: 'no', name: 'Norwegian' },
			{ code: 'pl', name: 'Polish' },
			{ code: 'pt-BR', name: 'Portuguese (Brazil)' },
			{ code: 'pt-PT', name: 'Portuguese (Portugal)' },
			{ code: 'ro', name: 'Romanian' },
			{ code: 'ru', name: 'Russian' },
			{ code: 'sr', name: 'Serbian' },
			{ code: 'zh', name: 'Chinese' },
			{ code: 'zh-CN', name: 'Chinese (PRC)' },
			{ code: 'zh-TW', name: 'Chinese (Taiwan)' },
			{ code: 'sk', name: 'Slovak' },
			{ code: 'sl', name: 'Slovenian' },
			{ code: 'es', name: 'Spanish' },
			{ code: 'sw', name: 'Swahili' },
			{ code: 'sv', name: 'Swedish' },
			{ code: 'th', name: 'Thai' },
			{ code: 'tr', name: 'Turkish' },
			{ code: 'uk', name: 'Ukrainian' },
			{ code: 'vi', name: 'Vietnamese' },
		],
	}),
	watch: {
		'locale.code'(v) {
			this.locale.name = this.allLocales.filter(l => l.code == v)?.[0]?.name
		},
	},
	methods: {
		// TODO: move to app?
		async load() {
			const locales = await this.$httpGet(this.endpoint + '/locales')
			this.locales = locales.items
		},
		async save() {
			if (this.id == 'new') {
				await this.$httpPost(this.endpoint + '/locales/', this.locale)
				this.load()
				return
			}
			const locales = await this.$httpPut(this.endpoint + '/locales/' + this.locale.sys.id, this.locale)
			this.locales = locales.items
		},
		close() {
			this.$router.push(this.base + '/settings/locales')
		},
		async deleteLocale() {
			if (!confirm('Are you sure you want to delete this locale?')) return
			await this.$httpDelete(this.endpoint + '/locales/' + this.locale.sys.id)
			this.close()
		},
		async saveAndClose() {
			await this.save()
			this.close()
		},
	},
	async mounted() {
		await this.load()
		this.id = this.$route.params.id
		if (this.id == 'new') {
			this.locale = {
				// TODO
				name: '',
				//'internal_code': '',
				code: '',
				fallbackCode: null,
				//default: null,
				contentManagementApi: true,
				contentDeliveryApi: true,
				optional: false,
			}
			return
		}
		// copy model for edit
		const locale = this.locales.filter(l => l.sys.id == this.id)[0]
		this.locale = JSON.parse(JSON.stringify(locale))
		document.title = 'Locale - ' + this.locale.name
	},
}
</script>

<style scoped>
h2 { margin: 20px 0px 1rem; padding: 0px; font-weight: 600; color: rgb(17, 27, 43); font-size: 1rem; line-height: 1.5rem; }
.localePicker { min-width: 500px; display: block; background-color: white; color: rgb(65, 77, 99); font-size: 0.875rem; line-height: 1.25rem; border-radius: 6px; box-shadow: rgb(225 228 232 / 20%) 0px 2px 0px inset; outline: none; border: 1px solid rgb(207, 217, 224); cursor: pointer; padding: 10px 1.5rem 10px 0.75rem; }
.checkbox { padding-left: 25px; position: relative; margin-bottom: 25px; color: #999; }
.checkbox input { position: absolute; left: 0; }
.checkbox label { font-weight: 600; display: block; color: rgb(17, 27, 43); margin-bottom: 3px; }
</style>