
import Head from './Head.vue'
import EntryApiMixin from '../components/EntryApiMixin.js'
import FieldCompare from '../components/fields/FieldCompare.vue'
import Spinner from '../components/Spinner.vue'
import Menu from '../components/Menu.vue'
import EntryStatus from '../components/fields/EntryStatus.vue'
import { formatDate, userName } from '../utils'
import ProviderMixin from './ProviderMixin.js'
// TODO: https://app.contentful.com/spaces/bali6j22f8ll/entries/1ULRakZiQOdHwTzeav9j8w/compare/4laxwFZ4lemdHiqr71UGHI
// TODO: http://localhost:8080/spaces/bali6j22f8ll/environments/Andreaslah-test/entries/1ULRakZiQOdHwTzeav9j8w/compare/4laxwFZ4lemdHiqr71UGHI

export default {
	name: 'ContentCompare',
	components: { Head, FieldCompare, Spinner, Menu, EntryStatus },
	mixins: [ EntryApiMixin, ProviderMixin ],
	inject: [ 'base' ],
	props: {
		locales: Array,
		defaultLocale: String,
		fallbackLocale: String,
	},
	data: () => ({
		entryId: null,
		versionId: null,
		selection: {},
		oldModel: null,
		onlyDiff: false,
		initialLoading: true,
		versions: [],
	}),
	computed: {
		contentType() {
			const id = this.model?.sys.contentType.sys.id
			return this.typeLookup[id]
		},
		typeFieldsById() {
			const r = {}
			for (const field of this.contentType.fields) {
				r[field.id] = field
			}
			return r
		},
		title() {
			const entry = this.model
			const df = this.contentType.displayField ?? 'title'
			const dl = this.defaultLocale
			const fl = this.fallbackLocale
			return entry?.fields?.[df]?.[dl] ?? entry?.fields?.[df]?.[fl] ?? 'Untitled'
		},
	},
	methods: {
		back() {
			// TODO-2: i think we need to restore an editor stack
			//       how to do that? the editor stack currently dows not have special urls, it only uses the url of the bottom entry.
			//       should we put the state into localstorage?
			//       should we not treat this view as a router path, but instead show it as overlay so we can just close it?
			//       CF uses paths like this for the stacks: entries/1RemaKx8mjXjQPvBbLuCnQ?previousEntries=1ULRakZiQOdHwTzeav9j8w
			//       probably that would be best. but it will be quite complicated - this needs url changes without router pushes.
			this.$router.push(this.base + '/entries/' + this.entryId)
		},
		// TODO-3: unify with loadModel in mixin?
		async loadSnapshot(version) {
			if (version) {
				this.versionId = version.sys.id
				this.$router.replace(this.base  + '/entries/' + this.entryId + '/compare/' + this.versionId)
			}

			this.loading++
			// https://api.contentful.com/spaces/bali6j22f8ll/entries/1ULRakZiQOdHwTzeav9j8w/snapshots/4laxwFZ4lemdHiqr71UGHI
			const entries = await this.$httpGet(this.endpoint + '/entries/' + this.entryId + '/snapshots/' + this.versionId)
			const model = entries.snapshot

			// create empty fields where missing based on the type info
			const id = model?.sys.contentType.sys.id
			const contentType = this.typeLookup[id]
			if (!model.fields) {
				model.fields = {}
			}
			for (const field of contentType.fields) {
				if (model.fields[field.id]) continue
				// TODO: we have to pass the de model in here so we have binding
				//       thats not ideal, as locales should be flexible..
				// TODO: this is not corrent for all types(?):
				//       array fields?
				//       ...?
				// TODO: check if we really need the de:undefined
				const value = { de: undefined }
				model.fields[field.id] = value
			}

			this.oldModel = model
			this.loading--
		},
		async loadVersions() {
			const versions = await this.$httpGet(this.endpoint + '/entries/' + this.entryId + '/snapshots?limit=100&select=sys%2Csnapshot.sys')
			this.versions = versions.items
		},
		fieldLocales(fieldId) {
			return this.typeFieldsById[fieldId].localized ? this.locales.map(l => l.code) : [ this.defaultLocale ]
		},
		selectAll(value = 'A', baseSelection, onlyDiff = false) {
			const selection = baseSelection ?? this.selection
			for (const control of this.editorInterface.controls) {
				for (const locale of this.fieldLocales(control.fieldId)) {
					const key = control.fieldId + '-' + locale
					const valueA = this.oldModel.fields[control.fieldId][locale]
					const valueB = this.model.fields[control.fieldId][locale]
					if (onlyDiff && JSON.stringify(valueA) == JSON.stringify(valueB)) continue
					selection[key] = value
				}
			}
			this.selection = selection
		},
		async save() {
			// mix & match a new model based on the selections and model + oldModel
			const newModel = JSON.parse(JSON.stringify(this.model))
			for (const control of this.editorInterface.controls) {
				for (const locale of this.fieldLocales(control.fieldId)) {
					const key = control.fieldId + '-' + locale
					const valueA = this.oldModel.fields[control.fieldId][locale]
					if (this.selection[key] == 'A')
						newModel.fields[control.fieldId][locale] = valueA
				}
			}
			await this.saveEntry(newModel)
			this.back()
		},
		formatDate,
		userName,
	},
	async mounted() {
		this.entryId = this.$route.params.id
		this.versionId = this.$route.params.version
		await this.loadContentTypes()
		await this.loadEntry()
		document.title = this.title + ' - Compare'
		await this.loadSnapshot()
		await this.loadEditorInterface()
		this.initialLoading = false
		await this.loadReferences()

		this.selectAll('B', {})
	},
}
