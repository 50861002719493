
import SystemTags from '../components/fields/SystemTags.vue'
import ContentTypesComboMulti from '../components/fields/ContentTypesComboMulti.vue'
import FieldWrapper from '../components/fields/FieldWrapper.vue'
import ActionButton from '../components/ActionButton.vue'
import { parsePolicies, stringifyPolicies, relations, buildRightsTreeIam, normalizeTags } from '../permission-utils'
import Menu from '../components/Menu.vue'
import ProviderMixin from './ProviderMixin'
import TemplateBasedList from '../components/TemplateBasedList.vue'
import RoleConditionEditor from '../components/RoleConditionEditor.vue'

function notab(s) {
	return s.replace(/\t/g, '').replace(/^\n/, '').trim()
}

// Format Docs
// https://chillimetrix.alturos.com/confluence/pages/viewpage.action?pageId=249262406

export default {
	name: 'RoleEditIam',
	mixins: [ ProviderMixin ],
	components: { SystemTags, ContentTypesComboMulti, FieldWrapper, ActionButton, Menu, TemplateBasedList, RoleConditionEditor },
	inject: [ 'spaceId', 'environment' ],
	props: {
		role: Object,
	},
	data: () => ({
		mock: {
			"version": "2024-08-11",
			"statement": [
				{
					"effect": "allow",
					"action": ["ch:useFeature"],
					"resource": ["*"],
					"condition": {
						"stringEquals": {"ch:feature/name": ["entry","base"]}
					},
				},
				{
					"effect": "deny",
					"action": ["ch:upsertEntry"],
					"resource": ["*"],
					"condition": {
						"stringEquals": {"ch:entry/contentType": ["locationTest","numberTest"]},
					},
				},
				{
					"effect": "allow",
					"action": ["ch:upsertEntry"],
					"resource": ["*"],
					"condition": {
						"listContains": {
							"ch:entry/tags": [ "hugo", "test" ],
						},
					},
				},
				{ // ATT: this is an admin right
					"effect": "allow",
					"action": ["*"],
					"resource": ["*"],
				},
				{
					"effect": "allow",
					"action": ["ch:upsertEntry"],
					"resource": ["ch:bali1333-master/entries/OUTDOORACTIVE-1234567890"],
				},
				{
					"effect": "allow",
					"action": ["ch:tagEntry"],
					"resource": ["hugo"]
				}
			]
		},
		statementTemplates: {
			Allow: { effect: 'allow', action: [], resource: [ '*' ], condition: [] },
			Deny: { effect: 'deny', action: [], resource: [ '*' ], condition: [] },
			'Allow Features': { effect: 'allow', action: [ 'ch:useFeature' ], resource: [ '*' ], condition: [ { type: 'stringEquals', key: 'ch:feature/name', param: [ 'role', 'entry', 'tags', 'bulk' ] } ] },
			'Allow Content Types': { effect: 'allow', action: [ 'ch:readEntry', 'ch:upsertEntry', 'ch:deleteEntry', 'ch:publishEntry' ], resource: [ '*' ], condition: [ { type: 'stringEquals', key: 'ch:entry/contentType', param: [] } ] },
			'Deny Content Types': { effect: 'deny', action: [ 'ch:readEntry', 'ch:upsertEntry', 'ch:deleteEntry', 'ch:publishEntry' ], resource: [ '*' ], condition: [ { type: 'stringEquals', key: 'ch:entry/contentType', param: [] } ] },
		},
		id: null,
		policies: [],
		relations,
		update: 0,
	}),
	computed: {
		policyString() {
			this.update
			return this.stringifyIam(this.policies)
			//return stringifyPolicies(this.policies, this.role.policies)
		},
		buildRightsTree() {
			this.update
			return buildRightsTreeIam(this.policies)
		},
	},
	watch: {
		policies: {
			handler() { this.update++ },
			deep: true,
		},
	},
	methods: {
		addPermission(relation) {
			//this.policies.push(JSON.parse(JSON.stringify(relation)))
		},
		afterLoad() {
			//this.policies = parsePolicies(this.role.policies)
			this.policies = this.parseIam(this.mock)
		},
		beforeSave() {
			//this.role.policies = stringifyPolicies(this.policies, this.role.policies)
		},
		// TODO: move this mapping responsibility to RoleConditionEditor?
		parseIam(data) {
			const r = JSON.parse(JSON.stringify(data.statement))
			for (const item of r) {
				const c = []
				for (const type in item.condition) {
					const cond = item.condition[type]
					for (const key in cond) {
						const param = cond[key]
						const nc = { type, key, param }
						c.push(nc)
					}
				}
				item.condition = c
			}
			return r
		},
		stringifyIam(data) {
			// resources, params and param array items can be tags, we need to convert those to strings
			const r = JSON.parse(JSON.stringify(data))
			for (const item of r) {
				item.resource = normalizeTags(item.resource)
				const c = {}
				for (const cond of item.condition) {
					const type = cond.type
					const key = cond.key
					const param = normalizeTags(cond.param)
					if (Array.isArray(param)) {
						for (let i = 0; i < param.length; i++)
							param[i] = normalizeTags(param[i])
					}
					if (!c[type]) c[type] = {}
					c[type][key] = param
				}
				item.condition = c
			}
			return JSON.stringify({
				version: new Date().toISOString().substring(0, 16),
				statement: r,
			}, null, 2)
		},
		addResourceHandler(name, value, handlerContext) {
			const prefix = 'ch:' + this.spaceId + '-' + this.environment
			const r = prompt(notab(`
				Enter a resource name.
				Place a * at the end of your resource name to wildcard it:
				- * matches all resources
				- ${ prefix }/entries/* matches any entry or asset
				- ${ prefix }/entries/MYS-* matches any MyServices entry or asset
			`), prefix + '/entries/*')
			if (!r) return
			return r
		},
	},
	mounted() {
		this.afterLoad()
	},
}
